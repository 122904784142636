import type { ComputedRef, MaybeRef, Reactive, Ref } from 'vue'
import {
  type AddCalculated,
  type RepeaterItem,
  joinPath,
  useFormula,
} from '@manager'
import { parseValue } from '@manager/utils/diffManagerForm/utils'
import { RESERVED_KEYS } from '../useRepeater'

export type ITableDataItem<T extends Record<string, any>> = RepeaterItem<
  AddCalculated<T>
>

export interface IUseTableDataOptions<T extends Record<string, any>> {
  min?: number
  max?: number
  path?: string
  initialItem?: Ref<Partial<T>> | (() => Partial<T>)
  watchInitialItem?: boolean
  initialItemCondition?: (context: {
    index: number
    item: T
    newInitialItem?: Partial<T>
    oldInitialItem?: Partial<T>
  }) => boolean
  onAdd?: (
    item: Reactive<ITableDataItem<T>>,
    index: number,
    array: ITableDataItem<T>[],
  ) => void
  onUpdate?: (
    item: Reactive<ITableDataItem<T>>,
    index: number,
    array: ITableDataItem<T>[],
  ) => void
  onRemove?: (
    item: Reactive<ITableDataItem<T>>,
    index: number,
    array: ITableDataItem<T>[],
  ) => void
  onCalculateProperty?: (item: RepeaterItem<T>, key: keyof T) => void
  evaluate?: (
    item: Reactive<ITableDataItem<T>>,
    index: number,
    array: ITableDataItem<T>[],
  ) => { [K in keyof T]?: MaybeRef<string | null | undefined> }
}

export interface IUseTableDataReturn<T extends Record<string, any>> {
  data: Ref<ITableDataItem<T>[]>
  filteredData: ComputedRef<ITableDataItem<T>[]>
  add: (newItem: T) => void
  update: (item: T, newItem: Partial<T>) => void
  addOrUpdate: (index: number, newItem: Partial<T>) => void
  batchUpdate: (newItem: Partial<T>) => void
  remove: (item: ITableDataItem<T>, index?: number) => void
  removeById: (id: string) => void
  get: (index: number) => ITableDataItem<T> | undefined
  getById: (id: string) => ITableDataItem<T> | undefined
  updateById: (id: string, item: Partial<T>) => void
  canAdd: ComputedRef<boolean>
  canRemove: ComputedRef<boolean>
}

export const useTableData = <T extends Record<string, any>>(
  initialValue: MaybeRef<T[]> = [],
  options: IUseTableDataOptions<T> = {},
): IUseTableDataReturn<T> => {
  const {
    min,
    max,
    path,
    initialItem = {} as Partial<T>,
    watchInitialItem = false,
    initialItemCondition,
    onAdd,
    onUpdate,
    onRemove,
    onCalculateProperty = (item, key) => item[key],
    evaluate,
  } = options

  const EVALS_CACHE = ref<{ [K in keyof T]?: Ref<any> }[]>([]) as Ref<
    { [K in keyof T]?: Ref<any> }[]
  >
  const { createFormula } = useFormula()
  const joinDataPath = (path: string, index: number) =>
    joinPath(path, `data[${index}]`)

  const {
    items: data,
    filteredItems: filteredData,
    add,
    update,
    ...repeater
  } = useRepeater<T>(initialValue, {
    min,
    max,
    onAdd: (item, index, array) => {
      const shouldApplyInitialItem =
        typeof initialItemCondition === 'function'
          ? initialItemCondition({ item, index })
          : true
      const _initialItem = toValue(initialItem)
      // Item keys
      const keys = [
        ...new Set([...Object.keys(item), ...Object.keys(_initialItem)]),
      ]

      // Initialize evaluations cache
      if (evaluate) EVALS_CACHE.value[index] = {}

      for (const key of keys) {
        // Skip reserved keys
        if (RESERVED_KEYS.includes(key)) continue

        // Initial value
        // @ts-expect-error
        item[key] =
          parseValue(item[key]) ??
          (shouldApplyInitialItem ? _initialItem[key] : null)

        // Calculated property
        if (!keys.includes(`calculated_${key}`)) {
          Object.defineProperty(item, `calculated_${key}`, {
            get: function () {
              return onCalculateProperty.call(this, this, key)
            },
            enumerable: false,
            configurable: true,
          })
        }

        // Evaluate formula
        if (evaluate) {
          if (!path) {
            console.error(new Error('Path is required when using evaluate'))
            continue
          }

          // Get defined formula
          const formula = evaluate(
            item as Reactive<ITableDataItem<T>>,
            index,
            array as ITableDataItem<T>[],
          )[key]

          if (!formula) continue

          // Evaluate and save on cache
          EVALS_CACHE.value[index][key as keyof T] = createFormula(
            formula,
            joinDataPath(path, index),
          ).evaluated
        }
      }

      // @ts-expect-error - Cast to ITableDataItem<T>
      onAdd?.(item, index, array)
      // TODO: Should we seal it?
      // Object.seal(item)
    },
    // @ts-expect-error - Cast to ITableDataItem<T>
    onUpdate,
    onRemove: (item, index, array) => {
      if (EVALS_CACHE.value[index]) delete EVALS_CACHE.value[index]
      // @ts-expect-error - Cast to ITableDataItem<T>
      return onRemove?.(item, index, array)
    },
  })

  const addOrUpdate = (index: number, newItem: T) => {
    const item = data.value[index]

    if (item) {
      update(item, newItem)
    } else {
      add(newItem)
    }
  }

  const batchUpdate = (newItem: Partial<T>) => {
    data.value.forEach((item) => {
      update(item, newItem)
    })
  }

  // Update items when initialValue changes,
  // but only for properties that were using the initial value
  if (watchInitialItem) {
    watch(
      () => toValue(initialItem),
      (newInitialValue, oldInitialValue) => {
        data.value.forEach((item, index) => {
          const shouldApplyInitialItem =
            typeof initialItemCondition === 'function'
              ? initialItemCondition({
                  index,
                  item,
                  newInitialItem: newInitialValue,
                  oldInitialItem: oldInitialValue,
                })
              : true
          if (!shouldApplyInitialItem) return

          for (const key in newInitialValue) {
            const hasChanged = oldInitialValue[key] !== newInitialValue[key]
            const shouldUpdate =
              item[key] === undefined ||
              item[key] === null ||
              item[key] === oldInitialValue[key] ||
              item.__status === REPEATER_ITEM_STATUS.GENERATED

            if (hasChanged && shouldUpdate) {
              // @ts-expect-error - Dynamic key/value
              update(item, { [key]: newInitialValue[key] })
            }
          }
        })
      },
    )
  }

  // Watch for changes in evaluations and update the item accordingly
  if (evaluate) {
    watch(
      EVALS_CACHE,
      () => {
        data.value.forEach((item, index) => {
          const cache = EVALS_CACHE.value[index]
          if (!cache) return

          for (const key in cache) {
            // @ts-expect-error - The key comes from the same object
            item[key] = cache[key]!
          }
        })
      },
      { deep: true },
    )
  }

  return {
    data: data as Ref<ITableDataItem<T>[]>,
    filteredData: filteredData as ComputedRef<ITableDataItem<T>[]>,
    add,
    update,
    addOrUpdate,
    batchUpdate,
    ...repeater,
  } as IUseTableDataReturn<T>
}
